/* eslint-disable import/no-anonymous-default-export */
import axios from 'axios';
import configData from "../config.json";
import authHeader from './auth-header';

const API_URL = configData.REACT_APP_API_URL;
const TRANSACTION_WITHDRAW_URL = `${API_URL}/transaction/withdraw`
const TRANSACTION_HISTORY_URL = `${API_URL}/transaction/history`

class TransactionService {
  withdraw(amount) {
    return axios.post(TRANSACTION_WITHDRAW_URL, { amount },{ headers: authHeader() })
      .then(response => {
        return response;
      });
  }
  history(type) {
    return axios.get(TRANSACTION_HISTORY_URL+'/'+type, { headers: authHeader() })
      .then(response => {
        return response;
      });
  }
}

export default new TransactionService();