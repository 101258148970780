import React, {useState, useEffect, useRef} from "react";
import { useNavigate } from "react-router-dom";
import authService from "../../services/auth.service";
import helpersService from "../../services/helpers.service";
import { useFormik } from 'formik';
import transactionService from "../../services/transaction.service";
import * as Yup from 'yup'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

const initialValues = {
    amount: '',
}
const schema = Yup.object().shape({
    amount: Yup.string().matches(
        /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
        "จำนวนเงินที่ต้องการถอนต้องเป็นตัวเลขเท่านั้น"
    )
    .required('กรุณาใส่จำนวนเงินที่ต้องการถอน'),
})
const Withdraw = ({wallet, setting}) => {
    const [user, setUser] = useState(null)
    const [submitState, setSubmitState] = useState(false)
    const bottomRef = useRef(null);
    const navigate = useNavigate();
    
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: schema,
        onSubmit: async (values, {setStatus, setSubmitting}) => {
            if(parseFloat(values.amount) < parseFloat(setting.SETTING_MINIMUM_WITHDRAW)){
                MySwal.fire({
                    icon: 'error',               
                    title: <p>ถอนขั้นต่ำ {helpersService.encodeMoneyFormat(setting.SETTING_MINIMUM_WITHDRAW)} บาท</p>,
                  })
                return;
            }
            MySwal.fire({
                title: 'โหลด...',
                allowOutsideClick: false,
                didOpen: () => {
                    MySwal.showLoading()
                },
            });
            setSubmitState(true);
            try {
               
                const {data: dataWithdraw} = await transactionService.withdraw(values.amount)
                if (dataWithdraw.code === 200) {
                    MySwal.fire({
                        icon: 'success',               
                        title: <p>ถอนสำเร็จ</p>,
                        showConfirmButton: false,
                        timer: 1500
                      }).then(function(result){
                        MySwal.fire({
                            title: 'โหลด...',
                            allowOutsideClick: false,
                            didOpen: () => {
                                MySwal.showLoading()
                            },
                        });
                        window.location.reload(false);
                    });
                }else{
                    MySwal.fire({
                        icon: 'error',               
                        title: <p>ถอนไม่สำเร็จ</p>,
                        text: dataWithdraw.message,
                      })
                }
                setSubmitState(false);
            } catch (error) {
                console.error(error)
                localStorage.clear();
                navigate('/auth/login');
            }
        },
    });
    
    useEffect(() => {
        const getUser = async () => {
            try {
                const dataUser = await authService.getCurrentUser();
                setUser(dataUser);
            }catch (error) {
                console.error(error)
                localStorage.clear();
                navigate('/auth/login');
            }
            
        }
        if(user === null){
            getUser()
        }
       
        
    }, []);
    setTimeout(() => {
        bottomRef.current?.scrollIntoView({behavior: 'smooth'});
    }, 700);
    return (
        <>
        <div className="headerprocess"><i className="fal fa-minus-circle"></i> ถอนเงิน</div>
        <div className="containprocess mt-3 px-3">
            <span className="detailaf">คำแนะนำ</span> อาจมีเครดิตคงค้างอยู่ค่ายเกมส์ต่าง ๆ ท่านสามารถกดปุ่มดึงเครดิตกลับกระเป๋าหลักได้
        </div>
        <div className="containprocess px-3 pt-2">
            <table className="tablewd01" align="center" style={{width: "100%"}}>
                <tbody>
                    {
                        user && user.data.bankWithdraw ? (<>
                        <tr>
                            <td className="text-left">
                                <span className="font-20 font-weight-bold">ถอนเงินเข้าบัญชีธนาคาร</span> <br/>
                                ชื่อ : {user && user.name} <br/>
                                เลขที่บัญชี : {user && (user.withdrawBankAccountNo && user.withdrawBankAccountNo.length > 10 ? helpersService.formatNo(user.withdrawBankAccountNo,'###-#-#####-###') : helpersService.formatNo(user.withdrawBankAccountNo,'###-#-#####-#'))} <br/>
                                { user && user.data.bankWithdraw.nameTh} 
                            </td>
                            <td style={{textAlign: "center"}}>
                                <img src={ user ? user.data.bankWithdraw.data.imageUrl : ''} alt="" width="70px" />
                            </td>
                        </tr>
                        </>) : (<>
                            <tr>
                                <td className="text-left">
                                    <span className="font-20 font-weight-bold">ถอนเงินเข้าบัญชีธนาคาร</span> <br/>
                                    ชื่อ : {user && user.name} <br/>
                                    เลขที่บัญชี : {user && (user.bankAccountNo.length > 10 ? helpersService.formatNo(user.bankAccountNo,'###-#-#####-###') : helpersService.formatNo(user.bankAccountNo,'###-#-#####-#'))} <br/>
                                    { user && user.data.bank.nameTh} 
                                </td>
                                <td style={{textAlign: "center"}}>
                                    <img src={ user ? user.data.bank.data.imageUrl : ''} alt="" width="70px" />
                                </td>
                            </tr>
                        </>)
                    }
                    
                </tbody></table>
        </div>
        <div className="containprocess p-3">
            <div className="wdsec02">
                <table className="tablewd01" style={{width: "100%"}}>
                    <tbody>
                        <tr>
                            <td>จำนวนเงินที่ถอนได้</td>
                            <td className="text-right"> ยอดถอนขั้นต่ำ</td>
                        </tr>
                        <tr>
                            <td id="creditApproved">฿ {helpersService.encodeMoneyFormat(wallet)}</td>
                            <td className="text-right"> ฿ {setting && helpersService.encodeMoneyFormat(setting.SETTING_MINIMUM_WITHDRAW)}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <form id="form_submit_withdraw" onSubmit={formik.handleSubmit}>
            <div className="tablewd01 mt-1 mx-auto containprocess">
                <div className="px-3">
                    <div className="form-group">
                        <span className="font-16">
                        จำนวนเงินที่ต้องการถอน </span>
                        <input type="text" id="amount" name="amount" className={
                                formik.errors.amount
                                ? "form-control loginform01 number mt-1 error"
                                : "form-control loginform01 number mt-1"
                            }
                             placeholder="฿ 0.00" value={formik.values.amount} onChange={formik.handleChange}/>
                        {formik.errors.amount ? <label id="amount-error" className="error" htmlFor="amount">{formik.errors.amount}</label> : null}
                    </div>
                </div>
            </div>
            <div className="text-center">
                <button id="btnSubmitWithdraw" className="mcolor colorbtn01" disabled={submitState} type="submit">
                {!submitState ? (<span><i className="fal fa-hand-holding-usd"></i> ยืนยันการถอน</span>)
                : (<span><i className="fad fa-spinner-third fa-spin "></i> โหลด...</span>)
                }
                </button>
            </div>
        </form>
       <br/>
       <br/>
       <br/>

        </>
    );
}
export default Withdraw;