/* eslint-disable import/no-anonymous-default-export */
import axios from 'axios';
import configData from "../config.json";
import authHeader from './auth-header';

const API_URL = configData.REACT_APP_API_URL;
const RETURNCREDIT_GET_URL = `${API_URL}/returnCredit/get`

class ReturnCreditService {
  get() {
    return axios.get(RETURNCREDIT_GET_URL,{ headers: authHeader() })
      .then(response => {
        return response;
      });
  }
}

export default new ReturnCreditService();