import {useState, useEffect} from "react";
import * as Yup from 'yup'
import { useFormik } from 'formik';
import BankService from '../services/bank.service'
import UserService from '../services/user.service'
import { useNavigate } from "react-router-dom";
import configData from "../config.json";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)
const IMAGE_URL = configData.REACT_APP_IMAGE_URL;
const initialValues = {
    bank: '',
    bankId: '',
    bankNo: '',
    phone: '',
    password: ''
  }
  const schema = Yup.object().shape({
    bank: Yup.string()
    .required('กรุณาเลือกธนาคาร'),
    bankNo: Yup.string().matches(
        /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
        "หมายเลขบัญชีต้องเป็นตัวเลขเท่านั้น"
      )
    .required('กรุณาใส่หมายเลขบัญชี'),
    phone: Yup.string()
    .required('กรุณาใส่เบอร์โทรศัพท์'),
    password: Yup.string()
    .required('กรุณาใส่รหัสผ่านใหม่'),
})
function ForgotPassword() {
    const [loading, setLoading] = useState(true)
    const [submitLoading, setSubmitLoading] = useState(false);
    const [selectBank, setSelectBank] = useState(false)
    const [banks, setBanks] = useState([])
    const navigate = useNavigate();
    setTimeout(() => {
        setLoading(false);
    }, 700);
    
    
      const formik = useFormik({
        initialValues: initialValues,
        validationSchema: schema,
        onSubmit: async (values, {setStatus, setSubmitting}) => {
            setSubmitLoading(true);
            setStatus(null)
            try {
                const password = values.password
                const phone = values.phone
                const bank = values.bankId
                const bankAccountNo = values.bankNo
                
                const {data: response} = await UserService.forgotPassword(password, phone, bank, bankAccountNo)
                if (response.code === 200) {
                    MySwal.fire({
                        icon: 'success',               
                        title: <p>บันทึกสำเร็จ</p>,
                        })
                    setTimeout(() => {
                        setLoading(false)
                        navigate('/auth/login');

                    }, 500);
                }else{
                    setSubmitLoading(false);
                    setStatus('เกิดข้อผิดพลาด,'+response.message+' กรุณาลองใหม่อีกครั้ง')
                    MySwal.fire({
                        icon: 'error',               
                        title: 'เกิดข้อผิดพลาด,'+response.message+' กรุณาลองใหม่อีกครั้ง',
                        })
                }
            } catch (error) {
                console.error(error)
                setSubmitLoading(false);
                MySwal.fire({
                    icon: 'error',               
                    title: 'เกิดข้อผิดพลาด,'+error.response.data.message+' กรุณาลองใหม่อีกครั้ง',
                    })
                setStatus('เกิดข้อผิดพลาด,'+error.response.data.message+' กรุณาลองใหม่อีกครั้ง')
            }
            
        },
    });
    const fetchDataBank = async () => {
        try {
            const {data: dataBanks} = await BankService.get();
            
            setBanks(dataBanks.data);
        } catch (error) {
            console.error(error)
            
        }
    }
    useEffect(() => {
        fetchDataBank()
    }, []);
    const handleOnSelectBank = (bankname, bankid) => {
        formik.setFieldValue('bank',bankname);
        formik.setFieldValue('bankId',bankid);
        setSelectBank(false);
       
    }
    return (
        <>
         {loading && (
            <div className="preload">
                <img src={IMAGE_URL+"logo.webp"} className="img-fluid" alt="logo" width="50%" />
            </div>
          )}
            
            <div className="containlogin boxcolor aos-init aos-animate">
                <div className="logologin">
                    <img src={IMAGE_URL+"logo.webp"} className="imganimationlogin mt-5" alt="" />
                </div>
                <form id="form_submit" onSubmit={formik.handleSubmit}>
                    <div className="containinlogin">
                        <div className="text-center mt-1">
                            <h4 className="textlogin">ลืมรหัสผ่าน</h4>
                        </div>
                        <div className="mt-1">
                            <label htmlFor="exampleInputEmail1">เบอร์โทรศัพท์</label>
                            <div className="iconlogin">
                                <i className="fal fa-user"></i>
                            </div>
                            <input type="text" name="phone" className={
                                formik.errors.phone
                                ? "form-control loginform01 error"
                                : "form-control loginform01"
                            } required="" placeholder="เบอร์โทรศัพท์" value={formik.values.phone} onChange={formik.handleChange} />
                            {formik.errors.phone ? <label className="error">{formik.errors.phone}</label> : null}
                        </div>
                        <div className="mt-1">
                            <label>ธนาคาร</label>
                            <div className="iconlogin">
                                <i className="fal fa-university"></i>
                            </div>
                            <input type="text" readOnly 
                            className={
                                formik.errors.bank
                                ? "form-control loginform01 open_select_bank error"
                                : "form-control loginform01 open_select_bank"
                            } name="bank" style={{cursor: "pointer"}} id="bank" placeholder="เลือกธนาคาร" value={formik.values.bank} onChange={formik.handleChange} onClick={() => {setSelectBank(true);}} />
                            <input type="hidden" name="financial_service_provider_id" id="financialServiceProviderID" value={formik.values.bankId} onChange={formik.handleChange} />
                            {formik.errors.bank ? <label id="bank-error" className="error" htmlFor="bank">{formik.errors.bank}</label> : null}

                        </div>
                        <div className="mt-2">
                            <label htmlFor="exampleInputEmail1">หมายเลขบัญชี</label>
                            <div className="iconlogin">
                                <i className="fal fa-sort-numeric-up-alt"></i>
                            </div>
                            <input type="text" className={
                                formik.errors.bankNo
                                ? "form-control loginform01 error"
                                : "form-control loginform01"
                            } name="bankNo" id="bankNo" placeholder="กรอกหมายเลขบัญชี" value={formik.values.bankNo} onChange={formik.handleChange}/>
                            {formik.errors.bankNo ? <label id="bankNo-error" className="error" htmlFor="bankNo">{formik.errors.bankNo}</label> : null}
                        </div>
                        
                        <div className="mt-2">
                            <label htmlFor="exampleInputEmail1">รหัสผ่าน</label>
                            <div className="iconlogin">
                                <i className="fal fa-lock"></i>
                            </div>
                            <input type="password" name="password" className={
                                formik.errors.password
                                ? "form-control loginform01 error"
                                : "form-control loginform01"
                            } required="" placeholder="รหัสผ่าน" value={formik.values.password} onChange={formik.handleChange}/>
                            {formik.errors.password ? <label className="error">{formik.errors.password}</label> : null}
                        </div>
                    </div>
                    <div className="text-center mt-4">
                        <button id="btn-login" className="mcolor colorbtn01" disabled={submitLoading} type="submit">
                            {!submitLoading ? (<span><i className="fal fa-sign-in"></i> ยืนยัน</span>)
                            : (<span><i className="fad fa-spinner-third fa-spin "></i> โหลด...</span>)
                            }
                            
                        </button>
                    </div>
                </form>
                <div onClick={() => { navigate('/auth/login'); } } className="needregister bkcolor mt-4"> ยกเลิก</div>

            </div>
            <div className="bankselectpopup py-0 px-2" style={{display: !selectBank ? "none" : "unset"}}>
                <div className="inbankselectpopup bkcolor">
                    <button className="btnclosebankselect" onClick={() => {setSelectBank(false);}}><i className="fal fa-times"></i></button>
                    <table>
                        <tbody id="select_bank">
                        {
                            banks.map(function(bank, i){
                                return <tr key={i}><td><a href="#" onClick={() => {handleOnSelectBank(bank.nameTh,bank.id)}}><img className="selectbank" name={bank.nameTh} atr={bank.id}  src={bank.data.imageUrl} alt="" /><br/> {bank.nameTh} </a></td></tr>
                            })
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );

}

export default ForgotPassword;