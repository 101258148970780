import {useState, useEffect} from "react";
import { useFormik } from 'formik';
import AuthService from '../../services/auth.service'
import { useNavigate } from "react-router-dom";
import configData from "../../config.json";
import settingService from "../../services/setting.service";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)
const IMAGE_URL = configData.REACT_APP_IMAGE_URL;
const queryParams = new URLSearchParams(window.location.search);
const usernamePar = queryParams.get("username");
const passwordPar = queryParams.get("password");
const validate = values => {
    const errors = {};
    if (!values.username) {
      errors.username = 'ชื่อผู้ใช้ / เบอร์โทรศัพท์';
    }
  
    if (!values.password) {
      errors.password = 'กรุณากรอกรหัสผ่าน';
    } 
  
    return errors;
  };

function Login() {
    const [loading, setLoading] = useState(true)
    const [submitState, setSubmitState] = useState(false)
    const [setting, setSetting] = useState(null)
    const navigate = useNavigate();

    useEffect(() => {
        const loginFromPar = async () => {
            setLoading(true)
            setSubmitState(true);
            try {
              const {data: auth} = await AuthService.login(usernamePar, passwordPar)
              if (auth.access_token) {

                const accessToken = auth.access_token;
                const user = auth.data;
                const af = auth.affiliate;
                localStorage.clear();
                localStorage.setItem("noone-wallet-token", accessToken);
                localStorage.setItem("noone-wallet-user", JSON.stringify(user));
                localStorage.setItem("noone-wallet-af", JSON.stringify(af));
                navigate('/');
                
              }else{
                MySwal.fire({
                    icon: 'error',               
                    title: <p>เข้าสู่ระบบไม่สำเร็จ</p>,
                    })
                setLoading(false)
                setSubmitState(false);
              }
              
            } catch (error) {
                console.error(error)
                MySwal.fire({
                    icon: 'error',               
                    title: <p>เข้าสู่ระบบไม่สำเร็จ</p>,
                    })
                setLoading(false)
                setSubmitState(false);
            }
        }
        if(usernamePar !== null && passwordPar !== null){
            loginFromPar();
        }
        const getSetting = async () => {
            try {
                const {data: dataSetting} = await settingService.get();
                setSetting(dataSetting.data);
            }catch (error) {
                console.error(error)
                localStorage.clear();
                navigate('/auth/login');
            }
        }
        if(setting === null){
            getSetting();
        }
        
    }, []);
    
    const formik = useFormik({
        initialValues: {
          username: '',
          password: ''
        },
        validate,
        onSubmit: async (values, {setStatus, setSubmitting}) => {
            setLoading(true)
            setStatus(null)
            setSubmitState(true);
            try {
              const {data: auth} = await AuthService.login(values.username, values.password)
              if (auth.access_token) {
                const accessToken = auth.access_token;
                const user = auth.data;
                const af = auth.affiliate;

                localStorage.clear();
                localStorage.setItem("noone-wallet-token", accessToken);
                localStorage.setItem("noone-wallet-user", JSON.stringify(user));
                localStorage.setItem("noone-wallet-af", JSON.stringify(af));
                navigate('/');
                
                // setTimeout(() => {
                // }, 500);
              }else{
                setStatus('The login details are incorrect')
                setLoading(false)
                setSubmitState(false);
              }
              
            } catch (error) {
                console.error(error)
                setStatus('The login details are incorrect')
                setLoading(false)
                setSubmitState(false);
            }
            values.preventDefault();

        },
      });

    setTimeout(() => {
        setLoading(false);
    }, 700);
    return (
        <>
         {loading && (
            <div className="preload">
                <img src={IMAGE_URL+"logo.webp"} className="img-fluid" alt="logo" width="50%" />
            </div>
          )}
            
            <div className="containlogin boxcolor aos-init aos-animate">
                <div className="logologin">
                    <img src={IMAGE_URL+"logo.webp"} className="imganimationlogin mt-5" alt="" />
                </div>
                <form id="form_submit" onSubmit={formik.handleSubmit}>
                    <div className="containinlogin">
                        <div className="text-center mt-1">
                            <h4 className="textlogin">เข้าสู่ระบบ</h4>
                        </div>
                        {formik.status && (
                            <div className='mb-lg-15 alert alert-danger'>
                            <div className='alert-text font-weight-bold'>{formik.status}</div>
                            </div>
                        )}
                        <div className="mt-1">
                            <label htmlFor="exampleInputEmail1">ชื่อผู้ใช้ / เบอร์โทรศัพท์</label>
                            <div className="iconlogin">
                                <i className="fal fa-user"></i>
                            </div>
                            <input type="text" name="username" className={
                                formik.errors.username
                                ? "form-control loginform01 error"
                                : "form-control loginform01"
                            } required="" placeholder="ชื่อผู้ใช้ / เบอร์โทรศัพท์" value={formik.values.username} onChange={formik.handleChange} />
                            {formik.errors.username ? <label className="error">{formik.errors.username}</label> : null}
                        </div>
                        <div className="mt-2">
                            <label htmlFor="exampleInputEmail1">รหัสผ่าน</label>
                            <div className="iconlogin">
                                <i className="fal fa-lock"></i>
                            </div>
                            <input type="password" name="password" className={
                                formik.errors.password
                                ? "form-control loginform01 error"
                                : "form-control loginform01"
                            } required="" placeholder="รหัสผ่าน" value={formik.values.password} onChange={formik.handleChange}/>
                            {formik.errors.password ? <label className="error">{formik.errors.password}</label> : null}
                        </div>
                    </div>
                    <div className="text-right containinlogin">
                        <button onClick={() => { navigate('/forgotPassword'); } } type="button" className="needregister bkcolor mt-4" style={{width: '28%',minWidth: '0px',padding: '4px 0'}}>รีเซ็ตรหัสผ่าน</button>
                    </div>
                    <div className="text-center mt-4">
                        <button id="btn-login" className="mcolor colorbtn01" disabled={submitState} type="submit">
                            {!submitState ? (<span><i className="fal fa-sign-in"></i> เข้าสู่ระบบ</span>)
                            : (<span><i className="fad fa-spinner-third fa-spin "></i> โหลด...</span>)
                            }
                            
                        </button>
                    </div>
                </form>
                <div onClick={() => { navigate('/register'); } } className="needregister bkcolor mt-4"><i className="fal fa-user-plus"></i> สมัครสมาชิก</div>

            </div>
            <div className="linebtn">
                <img onClick={() => { window.open(setting && setting.SETTING_LINE); } } src="./images/chat.svg" width="70px" alt="" />
            </div>
        </>
    );

}

export default Login;