import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import bankService from "../../services/bank.service";
import helpersService from "../../services/helpers.service";
const Deposit = () => {
    const [bankAccounts, setBankAccounts] = useState([])
    const [isCopy, setIsCopy] = useState(false)
    const navigate = useNavigate();

    
    useEffect(() => {
        const getBankAccount = async () => {
            try {
                const {data: dataBanks} = await bankService.getBankAccount();
                if (dataBanks.code === 200) {
                    setBankAccounts(dataBanks.data);
                }else{
                    localStorage.clear();
                    navigate('/auth/login');
                }
                 
            } catch (error) {
                console.error(error)
                localStorage.clear();
                navigate('/auth/login');
            }
            
        }
        if(bankAccounts.length <= 0){
            getBankAccount()
        } 
         
     }, []);
    const copyAccount = (value) => {
        navigator.clipboard.writeText(value)
        setIsCopy(true);
        setTimeout(() => {
            setIsCopy(false);
        }, 2000);
    }
    
    return (
        <>
        <div className="headerprocess"><i className="fal fa-gamepad-alt"></i> ฝากเงิน</div>
        <div id="wrapperPromotion"></div>
        <div className="containprocess pt-2">
            <div id="bank" className="tabgame">
                <div className="panel">
                    <div className="py-1">
                        <div align="center" className="tabletruewallet">
                            <div id="bank_account">
                            {bankAccounts.length > 0 ?
                                bankAccounts.map((bankAccount, i) => {
                                    
                                    return (
                                    <div key={i}>
                                        <img src={bankAccount.imageUrl} alt="" width="80px" className="mb-1"  /><br/>
                                        {bankAccount.nameTh} <br/>
                                        {(bankAccount.bankAccountNo.length > 10 ? helpersService.formatNo(bankAccount.bankAccountNo,'###-#-#####-###') : helpersService.formatNo(bankAccount.bankAccountNo,'###-#-#####-#'))}<br/>
                                        {bankAccount.bankAccountName}<br/>
                                        <button className="copybtn mcolor" onClick={() => {copyAccount(bankAccount.bankAccountNo)}}>COPY <span hidden="">{(bankAccount.bankAccountNo.length > 10 ? helpersService.formatNo(bankAccount.bankAccountNo,'###-#-#####-###') : helpersService.formatNo(bankAccount.bankAccountNo,'###-#-#####-#'))}</span></button>
                                        <br/><br/>
                                    </div>
                                    
                                    )
                                }) : null
                            }
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="myAlert-top alert" style={{display: isCopy ? 'block' : 'none'}}>
            <i className="fad fa-check-circle font-30" style={{color: "#d2b882"}}></i>
            <br/>
            <strong className="text-light">คัดลอกเรียบร้อย!</strong>
        </div>
        </>
    );
}
export default Deposit;