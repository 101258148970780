import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import returnCreditService from "../../services/return-credit.service";
import helpersService from "../../services/helpers.service";
const ReturnCredit = () => {
    const [returnCredits, setReturnCredits] = useState([])
    const [sumReturnCredit, setSumReturnCredit] = useState(0)
    const navigate = useNavigate();
    useEffect(() => {
        const getReturnCredit = async () => {
            try {
                const {data: dataReturnCredit} = await returnCreditService.get();
                if (dataReturnCredit.code === 200) {
                    setReturnCredits(dataReturnCredit.data);
                    setSumReturnCredit(dataReturnCredit.sum);
                }
               
                 
            } catch (error) {
                console.error(error)
                localStorage.clear();
                navigate('/auth/login');
            }
            
        }
        getReturnCredit()
        
     }, []);
    return (
        <>
        <div className="containfriend">
            <div className="bgcutinfriend">
                <div className="headerprocess"><i className="fal fa-badge-dollar"></i> คืนยอดเสีย</div>

                <div className="moneycontainaf mt-3">
                    <table width="100%">
                        <tbody>
                        <tr>
                            <td>
                                <i className="fas fa-badge-dollar"></i>
                            </td>
                            <td className="text-right">รับไปทั้งหมด <br/>
                                <span className="cutcolor font-25">{sumReturnCredit}</span>
                                {/* <br/>
                                <div className="position-relative">
                                    <br/>
                                    <div id="withdrawfriend">
                                        <button className="btn-grad btnWithdrawReturnBalance" type="button"><i className="fas fa-hand-holding-usd"></i> COMFIRM WITHDRAW</button>
                                    </div>
                                </div> */}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div id="balance_content" className="mt-5">
            </div>

            <div id="withdrawfriendtabs" className="mt-5">
                <table style={{width: '100%'}}>
                    <thead>
                        <tr>
                            <td>
                                วันที่
                            </td>
                            <td style={{textAlign: 'right'}}>
                                จำนวน
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        {returnCredits.length > 0 ? 
                        returnCredits.map((returnCredit, i) => {
                            return (
                                <tr key={i}>
                                    <td>{returnCredit.dateCreate}</td>
                                    <td style={{textAlign: 'right'}}>{helpersService.encodeMoneyFormat(returnCredit.amount)}</td>
                                </tr>
                            )
                        })
                        : null
                        }
                    </tbody>
                </table>
                {/* <div className="divoffriends mt-3">
                    <div className="text-center">NO DATA</div>
                </div> */}
            </div>
        </div>

        </>
    );
}
export default ReturnCredit;