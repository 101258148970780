/* eslint-disable import/no-anonymous-default-export */
import axios from 'axios';
import configData from "../config.json";
import authHeader from './auth-header';

const API_URL = configData.REACT_APP_API_URL;
const SETTING_GET_URL = `${API_URL}/setting/get`

class SettingService {
  get() {
    return axios.get(SETTING_GET_URL)
      .then(response => {
        return response;
      });
  }
}

export default new SettingService();