import axios from "axios";
import configData from "../config.json";
const API_URL = configData.REACT_APP_API_URL;

const LOGIN_URL = `${API_URL}/login`

class AuthService {
  login(username, password) {
    return axios.post(LOGIN_URL, { username, password })
      .then(response => {
        return response;
      });
  }

  logout() {
    localStorage.removeItem("noone-wallet-user");
    localStorage.removeItem("noone-wallet-token");
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('noone-wallet-user'));
  }
}

export default new AuthService();