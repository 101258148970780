import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import gameService from "../../services/game.service";
import userService from "../../services/user.service";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import helpersService from "../../services/helpers.service";
import configData from "../../config.json";
const API_URL = configData.REACT_APP_API_URL;
const MySwal = withReactContent(Swal)
const Betflix = ({creditBetflix,setCreditBetflix, setStatePage, statePage}) => {
    const [spin, setSpin] = useState(false)
    const [user, setUser] = useState(null)
    const [tabGame, setTabGame] = useState(false)
    const [tab, setTab] = useState('ALL')
    const [games, setGames] = useState([])
    const [gamesSub, setGamesSub] = useState([])
    const [logoGameSelect, setLogoGameSelect] = useState('')
    const navigate = useNavigate();
    const gameName = ['bs','ng','aws','swg','kg','jl','km','fc','mg','gamatron','ep','pp','netent','joker','bpg','bng','hab','kgl','rlx','ygg','red','qs','ids','tk','mav','ds','nlc','ga','png','pug','fng','nge','1x2','hak','waz','elk','prs']
    const getCredit = async () => {
        setSpin(true);
        try {
            const {data: dataCredit} = await gameService.getCredit(1);
            setCreditBetflix(dataCredit.credit);
            setSpin(false);
        }catch (error) {
            console.error(error)
            localStorage.clear();
            navigate('/auth/login');
        }
    }
    useEffect(() => {
        const getUserProfile = async () => {
            try {
                const {data: userProfile} = await userService.getProfile()
                if (userProfile.code === 200) {
                    const user1 = userProfile.data;
                    setUser(user1);
                    localStorage.setItem("noone-wallet-user", JSON.stringify(user1));
                    
                    
                }else{
                    localStorage.clear();
                    navigate('/auth/login');
    
                }
                    
            } catch (error) {
                console.error(error)
                localStorage.clear();
                navigate('/auth/login');
            }
            
        }
        if(user === null){
            getUserProfile()
        }
    }, []);
    
    const handleAccessGame = async (game) => {
        MySwal.fire({
            title: 'โหลด...',
            allowOutsideClick: false,
            didOpen: () => {
                MySwal.showLoading()
            },
        });
        let result = gameName.find(item => { return item === game.game });
        
        if(result){
            setLogoGameSelect(game.logo);
            try {
                const {data: dataGameSubList} = await gameService.getGameList(result+'.txt');
                if(dataGameSubList.code === 200){
                    setGamesSub(dataGameSubList.data);
                }
                setStatePage('betflix-sub');
                MySwal.close();
            }catch (error) {
                console.error(error)
                localStorage.clear();
                navigate('/auth/login');
            }
            
        }else{
            try {
                // const {data: dataPlayGame} = await gameService.playGameBetflix(game.game.toLowerCase(), game.id);
                // if(dataPlayGame.code === 200){
                //     window.open(dataPlayGame.data.launch_url);
                // }
                window.open(API_URL+'/game/playGameBetflixRedirect/'+user.id+'/'+game.game.toLowerCase()+'/'+game.id);
                MySwal.close();
            }catch (error) {
                console.error(error)
                localStorage.clear();
                navigate('/auth/login');
            }
        }
    }
    const openGame = async (game) => {
        MySwal.fire({
            title: 'โหลด...',
            allowOutsideClick: false,
            didOpen: () => {
                MySwal.showLoading()
            },
        });
        try {
            const {data: dataPlayGame} = await gameService.playGameBetflix(game.provider.toLowerCase(), game.code);
            if(dataPlayGame.code === 200){
                window.open(dataPlayGame.data.launch_url);
            }
            // window.open(API_URL+'/game/playGameBetflixRedirect/'+user.id+'/'+game.provider.toLowerCase()+'/'+game.id);
            
            MySwal.close();
        }catch (error) {
            console.error(error)
            localStorage.clear();
            navigate('/auth/login');
        }
    }
    const handleTab = async (t) => {
        setTab(t);
    }
    const getGameList = async () => {
        try {
            const {data: dataGameList} = await gameService.getGameList('list.txt');
            if(dataGameList.code === 200){
                setGames(dataGameList.data);
            }

        }catch (error) {
            console.error(error)
            localStorage.clear();
            navigate('/auth/login');
        }
    }
    
    useEffect(() => {
        getGameList()
    }, []);
    return (
        <>
        <div className="headerprocess"><i className="fal fa-gamepad-alt"></i> BETFLIX</div>
        <div style={{display:'block'}} className="mt-3">
            <div style={{'width': '90%',maxWidth: '500px', margin: '0 auto', }}>
                <div style={{textAlign: 'center'}}><span className="detailaf">เครดิตกระเป๋า BETFLIX <i id="creditBetflix">{helpersService.encodeMoneyFormat(creditBetflix)}</i> <i className={spin ? "fas fa-sync fa-spin" : "fas fa-sync "} onClick={() => {getCredit()}} style={{cursor: 'pointer'}} id="refreshCreditBetflix"></i></span></div>
            </div>
        </div>
        <div className="containprocess " style={{paddingTop: '20px',display: statePage==='betflix' ? 'block' : 'none'}}>
            <div className="main_wrapper mt-1" style={{background: 'linear-gradient(to top, #212121, #0c0c0c) !important',color: '#fff !important'}}>
                <div className="p-0">
                    <div className="row">
                        <div className="col-md-12 mb-3">
                            <div className="wrapper-game">
                                <center>
                                    <div id="myBtnContainer_grid">
                                        <div className="shadow_select_G">
                                            <div className="row pr-2 pl-2">
                                                <div className="col-2 p-0 m-0">
                                                    <button className={tab === 'ALL' ? 
                                                    "btn btn-provider-game-type select_G_grid w-100 rounded-left active" :
                                                    "btn btn-provider-game-type select_G_grid w-100 rounded-left"} onClick={() => handleTab('ALL')} data-provider-game-type="all">
                                                        <img src="https://www.083513.com/assets/image/menu/g_menu1_active.png" alt="" className="icon_g" />
                                                        <br/>
                                                        <span className="mm_none"> ทั้งหมด </span>
                                                    </button>
                                                </div>
                                                <div className="col-2 p-0 m-0">
                                                    <button className={tab === 'casino' ? 
                                                    "btn btn-provider-game-type select_G_grid w-100 active" :
                                                    "btn btn-provider-game-type select_G_grid w-100"} onClick={() => handleTab('casino')} data-provider-game-type="casino">
                                                        <img src="https://www.083513.com/assets/image/menu/g_menu2_active.png" alt="" className="icon_g" />
                                                        <br/>
                                                        <span className="mm_none"> คาสิโน </span>
                                                    </button>
                                                </div>
                                                <div className="col-2 p-0 m-0">
                                                    <button className={tab === 'slot' ? 
                                                    "btn btn-provider-game-type select_G_grid w-100 active" :
                                                    "btn btn-provider-game-type select_G_grid w-100"} onClick={() => handleTab('slot')} data-provider-game-type="slot">
                                                        <img src="https://www.083513.com/assets/image/menu/g_menu3_active.png" alt="" className="icon_g" />
                                                        <br/>
                                                        <span className="mm_none"> สล็อต </span></button>
                                                </div>
                                                <div className="col-3 p-0 m-0">
                                                    <button className={tab === 'fishing' ? 
                                                    "btn btn-provider-game-type select_G_grid w-100 active" :
                                                    "btn btn-provider-game-type select_G_grid w-100"} onClick={() => handleTab('fishing')} data-provider-game-type="fishing">
                                                        <img src="https://www.083513.com/assets/image/menu/g_menu4_active.png?v=0001" alt="" className="icon_g" />
                                                        <br />
                                                        <span className="mm_none"> เกม/ยิงปลา </span></button>
                                                </div>
                                                <div className="col-3 p-0 m-0">
                                                    <button className={tab === 'sport' ? 
                                                    "btn btn-provider-game-type select_G_grid w-100 rounded-right active" :
                                                    "btn btn-provider-game-type select_G_grid w-100 rounded-right"} onClick={() => handleTab('sport')} data-provider-game-type="sport">
                                                        <img src="https://www.083513.com/assets/image/menu/3naubbj.png?v=0001" alt="" className="icon_g" />
                                                        <br />
                                                        <span className="mm_none"> กีฬา </span></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </center>
                                <center>
                                    <div className="pd-grid-wrapper_drid">
                                        <div className="grid-wrapper_drid container mt-2">
                                            <div className="content">
                                            {games.length > 0 ?
                                                games.map((item, i) => {
                                                    
                                                    return item.type.search(tab) > -1 ? (
                                                    <div key={i} className="filter_grid grid-item show_g_grid" onClick={() => {handleAccessGame(item)}}>
                                                        <div className="bg-gradient-game_grid ">
                                                            <a href="#" className="popitup" data-provider-name={item.game}>
                                                                <div className="image shadow">
                                                                    <img src={item.logo} className="img-fluid logoGame" alt="" />
                                                                </div>
                                                            </a>
                                                        </div>
                                                        <div className="text-center">
                                                            <small className="wrapper-gametxtName"> {item.name} </small>
                                                        </div>
                                                    </div>
                                                    
                                                    ) : tab === 'ALL' ? (
                                                        <div key={i} className="filter_grid grid-item show_g_grid" onClick={() => {handleAccessGame(item)}}>
                                                            <div className="bg-gradient-game_grid ">
                                                                <a href="#" className="popitup" data-provider-name={item.game}>
                                                                    <div className="image shadow">
                                                                        <img src={item.logo} className="img-fluid logoGame" alt="" />
                                                                    </div>
                                                                </a>
                                                            </div>
                                                            <div className="text-center">
                                                                <small className="wrapper-gametxtName"> {item.name} </small>
                                                            </div>
                                                        </div>
                                                        
                                                        ) : null
                                                }) : null
                                            }
                                            
                                            </div>
                                        </div>
                                    </div>
                                </center>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="containprocess " style={{paddingTop: '20px',display: statePage==='betflix-sub' ? 'block' : 'none'}}>
            <div className="main_wrapper black_bg" style={{background: 'linear-gradient(to top, #212121, #0c0c0c) !important',color: '#fff !important'}}>
                <div className="p-0">
                    <div className="row">
                        <div className="col-md-12 pb-4">
                            <div className="wrapper-game">
                                <center>
                                    <div className="mt-1">
                                        <img src={logoGameSelect} style={{width: '150px', marginBottom:'-29px',marginTop: '-18px'}} alt=""  />
                                    </div>
                                    <div className="grid-wrapper_drid container">
                                        <div className="content">
                                            {gamesSub.length > 0 ?
                                                gamesSub.map((item, i) => {
                                                    
                                                    return (
                                                        <div key={i} className="filter_grid grid-item 1 show_g_grid" onClick={() => {openGame(item)}}>
                                                            <div className="bg-gradient-game_grid" style={{paddingLeft:'3px',paddingRight:'3px'}}>
                                                                <div className="image">
                                                                    <a href="javascript:void(0)" className="popitup" >
                                                                        <img src={item.provider === 'PP' || item.provider === 'EP' || item.provider === 'KG' || item.provider === 'ng' ? item.img : 'https://ardmhzelxcmj.ocrazeckyunc.com'+item.img} className="img-fluid rounded" style={{marginBottom: '5px'}} alt="" />
                                                                        <p className="listName_game">{item.name}</p>
                                                                        <div className="border_game-joker"></div>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }) : null
                                            }
                                            
                                        </div>
                                    </div>
                                </center>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}
export default Betflix;