import axios from "axios";
import configData from "../config.json";
import authHeader from './auth-header';
const API_URL = configData.REACT_APP_API_URL;

const BANK_GET_URL = `${API_URL}/bank/get`
const BANK_ACCOUN_GET_URL = `${API_URL}/bank/account`

class BankService {
  get() {
    return axios.get(BANK_GET_URL)
      .then(response => {
        return response;
      });
  }
  getBankAccount() {
    return axios.get(BANK_ACCOUN_GET_URL, { headers: authHeader() })
      .then(response => {
        return response;
      });
  }

}

export default new BankService();