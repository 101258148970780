import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from 'yup'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useFormik } from 'formik';
import userService from "../../services/user.service";
const MySwal = withReactContent(Swal)
const initialValues = {
    password: '',
}
const schema = Yup.object().shape({
    password: Yup.string()
    .required('กรุณาใส่รหัสผ่านใหม่'),
})
const ChangePassword = () => {
    const [submitState, setSubmitState] = useState(false)
    const navigate = useNavigate();
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: schema,
        onSubmit: async (values, {setStatus, setSubmitting}) => {
            
            MySwal.fire({
                title: 'โหลด...',
                allowOutsideClick: false,
                didOpen: () => {
                    MySwal.showLoading()
                },
            });
            setSubmitState(true);
            try {
               
                const {data: dataChangePass} = await userService.changePassword(values.password)
                if (dataChangePass.code === 200) {
                    MySwal.fire({
                        icon: 'success',               
                        title: <p>สำเร็จ</p>,
                        showConfirmButton: false,
                        timer: 1500
                      }).then(function(result){
                        MySwal.fire({
                            title: 'โหลด...',
                            allowOutsideClick: false,
                            didOpen: () => {
                                MySwal.showLoading()
                            },
                        });
                        window.location.reload(false);
                    });
                }else{
                    MySwal.fire({
                        icon: 'error',               
                        title: <p>ไม่สำเร็จ</p>,
                        text: dataChangePass.message,
                      })
                }
                setSubmitState(false);
            } catch (error) {
                console.error(error)
                localStorage.clear();
                navigate('/auth/login');
            }
        },
    });
    return (
        <>
        <div className="headerprocess"><i className="fal fa-lock"></i> เปลี่ยนรหัสผ่าน</div>
        <div className="containprocess py-0 px-3">
            <form id="form_submit" onSubmit={formik.handleSubmit}>
                <div className="mt-1">
                    <label htmlFor="exampleInputEmail1">รหัสผ่านใหม่</label>
                    <div className="iconlogin">
                        <i className="fal fa-lock"></i>
                    </div>
                    <input type="password" autoComplete="false" className={
                                formik.errors.password
                                ? "form-control loginform01 error"
                                : "form-control loginform01"
                            } id="password" name="password" placeholder="รหัสผ่านใหม่" value={formik.values.password} onChange={formik.handleChange}/>
                        {formik.errors.password ? <label id="password-error" className="error" htmlFor="password">{formik.errors.password}</label> : null}

                </div>
                <div className="text-center mt-4">
                    <button className="mcolor colorbtn01" id="btnChangePassword" disabled={submitState} type="submit">
                    {!submitState ? (<span><i className="fal fa-sign-in"></i> ยืนยัน</span>)
                    : (<span><i className="fad fa-spinner-third fa-spin "></i> โหลด...</span>)
                    }</button>
                </div>
            </form>
        </div>
        </>
    );
}
export default ChangePassword;