import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import userService from "../../services/user.service";
import gameService from "../../services/game.service";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import helpersService from "../../services/helpers.service";
import configData from "../../config.json";
const IMAGE_URL = configData.REACT_APP_IMAGE_URL;
const API_URL = configData.REACT_APP_API_URL;
const MySwal = withReactContent(Swal)
const Huay14 = ({creditHuay14,setCreditHuay14, setStatePage, statePage}) => {
    const [spin, setSpin] = useState(false)
    const [user, setUser] = useState(null)
    const [tabGame, setTabGame] = useState(false)
    const [tab, setTab] = useState('ALL')
    const [games, setGames] = useState([])
    const [gamesSub, setGamesSub] = useState([])
    const [logoGameSelect, setLogoGameSelect] = useState('')
    const navigate = useNavigate();
    useEffect(() => {
        const getUserProfile = async () => {
            try {
                const {data: userProfile} = await userService.getProfile()
                if (userProfile.code === 200) {
                    const user1 = userProfile.data;
                    setUser(user1);
                    localStorage.setItem("noone-wallet-user", JSON.stringify(user1));
                    
                    
                }else{
                    localStorage.clear();
                    navigate('/auth/login');
    
                }
                    
            } catch (error) {
                console.error(error)
                localStorage.clear();
                navigate('/auth/login');
            }
            
        }
        if(user === null){
            getUserProfile()
        }
    }, []);
    const getCredit = async () => {
        setSpin(true);
        try {
            const {data: dataCredit} = await gameService.getCredit(5);
            setCreditHuay14(dataCredit.credit);
            setSpin(false);
        }catch (error) {
            console.error(error)
            localStorage.clear();
            navigate('/auth/login');
        }
    }
   
    const openGame = async (game) => {
        MySwal.fire({
            title: 'โหลด...',
            allowOutsideClick: false,
            didOpen: () => {
                MySwal.showLoading()
            },
        });
        try {
            window.open(API_URL+'/game/playGameHuay14/'+game+'/'+user.id);

            // const {data: dataPlayGame} = await gameService.playGameHuay14();
            // if(dataPlayGame.code === 200){
            //     window.open(dataPlayGame.data);
            // }
            MySwal.close();
        }catch (error) {
            console.error(error)
            localStorage.clear();
            navigate('/auth/login');
        }
    }
    const handleTab = async (t) => {
        setTab(t);
    }
   
    return (
        <>
        <div className="headerprocess"><i className="fal fa-gamepad-alt"></i> Huay14</div>
        <div style={{display:'block'}} className="mt-3">
            <div style={{'width': '90%',maxWidth: '500px', margin: '0 auto', }}>
                <div style={{textAlign: 'center'}}><span className="detailaf">เครดิตกระเป๋า Huay14 <i id="creditHuay14">{helpersService.encodeMoneyFormat(creditHuay14)}</i> <i className={spin ? "fas fa-sync fa-spin" : "fas fa-sync "} onClick={() => {getCredit()}} style={{cursor: 'pointer'}} id="refreshCreditHuay14"></i></span></div>
            </div>
        </div>
        <div className="containprocess " style={{paddingTop: '20px',display: statePage==='huay14' ? 'block' : 'none'}}>
            <div className="mt-1" >
                <div className="p-0">
                    <div className="row">
                        <div className="col-md-12 mb-3">
                            <div className="wrapper-game">
                                <center>
                                    <div className="pd-grid-wrapper_drid">
                                        <div className="grid-wrapper_drid container mt-2">
                                            <div className="content">
                                                <div className="filter_grid grid-item show_g_grid" onClick={() => {openGame('lotto')}}>
                                                    <div className="bg-gradient-game_grid ">
                                                        <a href="#" className="popitup">
                                                            <div className="image shadow">
                                                                <img src={IMAGE_URL+'huay14.png'} className="img-fluid logoGame" alt="" />
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div className="text-center">
                                                        <small className="wrapper-gametxtName"> หวยรัฐบาลไทย </small>
                                                    </div>
                                                </div>
                                                {/* <div className="filter_grid grid-item show_g_grid" onClick={() => {openGame('stock')}}>
                                                    <div className="bg-gradient-game_grid ">
                                                        <a href="#" className="popitup">
                                                            <div className="image shadow">
                                                                <img src={IMAGE_URL+'huay14.png'} className="img-fluid stockGame" alt="" />
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div className="text-center">
                                                        <small className="wrapper-gametxtName"> หวยหุ้นไทย </small>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </center>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}
export default Huay14;