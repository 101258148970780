/* eslint-disable import/no-anonymous-default-export */
import axios from 'axios';
import configData from "../config.json";
import authHeader from './auth-header';

const API_URL = configData.REACT_APP_API_URL;
const PROMOTION_GET_URL = `${API_URL}/promotion/get`
const PROMOTION_RECEIVE_URL = `${API_URL}/promotion/receivePromotion`

class PromotionService {
  get() {
    return axios.get(PROMOTION_GET_URL,{ headers: authHeader() })
      .then(response => {
        return response;
      });
  }
  receivePromotion(id) {
    return axios.post(PROMOTION_RECEIVE_URL+'/'+id, {},{ headers: authHeader() })
      .then(response => {
        return response;
      });
  }
}

export default new PromotionService();