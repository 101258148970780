

class HealpersService {
  formatNo(value, pattern) {
    if (!value) {
      return '';
    }
    value = value.replace(/-/g, '');
    var i = 0,
      v = value.toString();
    var text = pattern.replace(/#/g, _ => {
      if (i < v.length) {
        return v[i++];
      } else {
        return '';
      }
    });
    if (text.substring(text.length - 1, text.length) === '-')
      return text.substring(0, text.length - 1);

    return text;
  }
  encodeMoneyFormat(input) {
    var signed = '';
    if (input < 0) signed = '-';
    if (input != null) {
      input = input + '';
    } else {
      input = '';
    }
    input = input.replace(/[,\-]+/g, "").trim();

    input = Math.round(Number(input) * 100) / 100;
    var parts = input.toString().split(".");
    var value = isNaN(parts[1]) ? "00" : parts[1];
    if (value.length < 2) {
      value = value + "0";
    }
    return signed + parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "." + value;
  }

}

export default new HealpersService();