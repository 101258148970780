import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import authService from "../../services/auth.service";
const Profile = ({setStatePage}) => {
    const [user, setUser] = useState(null)
    const navigate = useNavigate();
    useEffect(() => {
        const getUser = async () => {
            try {
                const dataUser = await authService.getCurrentUser();
                setUser(dataUser);
            }catch (error) {
                console.error(error)
                localStorage.clear();
                navigate('/auth/login');
            }
            
        }
        if(user === null){
            getUser()
        }
        
     }, []);
    return (
        <>
        <div className="headerprocess"><i className="fal fa-user"></i> PROFILE</div>
        <div className="containprocess py-0 px-3">
            <table align="center" className="accountofuser">
                <tbody>
                    <tr className="trofaccount">
                        <td className="headeraccount"><i className="fal fa-user"></i> YOU:</td>
                        <td>{user && user.name}</td>
                    </tr>
                    <tr className="trofaccount">
                        <td className="headeraccount"><i className="fal fa-user-circle"></i> USERNAME:</td>
                        <td>{user && user.username}</td>
                    </tr>
                    <tr className="trofaccount">
                        <td className="headeraccount"><i className="fal fa-lock"></i> PASSWORD:</td>
                        <td><i className="fas fa-key"  style={{cursor: "pointer"}} onClick={() => {setStatePage('changePassword');}}></i></td>
                    </tr>
                    <tr className="trofaccount">
                        <td className="headeraccount"><i className="fal fa-university"></i> BANK:</td>
                        <td>{user && user.data.bank.nameTh}</td>
                    </tr>
                    {/* <tr className="trofaccount">
                        <td className="headeraccount"><i className="fal fa-gift"></i> YOUR PROMOTION:</td>
                        <td>NO PROMOTION</td>
                    </tr> */}
                    {/* <tr className="trofaccount">
                        <td className="headeraccount"><i className="fal fa-users"></i> AFFILIATE:</td>
                        <td>0.00 BATH</td>
                    </tr> */}
                </tbody>
            </table>
        </div>

        </>
    );
}
export default Profile;