import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './components/auth/login';
import Auth from './components/auth/auth';
import Register from './components/register';
import ForgotPassword from './components/forgotpassword';
import App from './App';
import ProtectedRoute from './util/ProtectedRoute';
import Member from './components/member/index';
import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
// import { AuthProvider } from './context/AuthProvider';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter basename={'/'}>
      <Routes>
          <Route path='/auth' element={<Auth />}>
              <Route exact path='login' element={<Login />} />
          </Route>
          <Route path='/register' element={<Register />} />
          <Route path='/forgotPassword' element={<ForgotPassword />} />
          <Route path="/" element={<App />}>
              <Route path='' element={
                  <ProtectedRoute>
                      <Member />
                  </ProtectedRoute>
              } />
          </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
