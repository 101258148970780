import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import transactionService from "../../services/transaction.service";
import authService from "../../services/auth.service";
import helpersService from "../../services/helpers.service";
const History = () => {
    const [tabState, setTabState] = useState('deposit')
    const [historyDeposits, setHistoryDeposits] = useState([])
    const [historyWithdraws, setHistoryWithdraws] = useState([])
    const [user, setUser] = useState(null)
    const navigate = useNavigate();

    const handleChangeTab = (tab) => {
        setTabState(tab);

    }
    useEffect(() => {
        const getHistoryDeposit = async () => {
            try {
                const {data: dataHistoryDeposits} = await transactionService.history('DEPOSIT');
                if (dataHistoryDeposits.code === 200) {
                    setHistoryDeposits(dataHistoryDeposits.data);
                }
               
                 
            } catch (error) {
                console.error(error)
                localStorage.clear();
                navigate('/auth/login');
            }
            
        }
        if(historyDeposits.length <= 0){
            getHistoryDeposit()
        } 
        const getHistoryWithdraw = async () => {
            try {
               
                const {data: dataHistoryWithdraws} = await transactionService.history('WITHDRAW');
                if (dataHistoryWithdraws.code === 200) {
                    setHistoryWithdraws(dataHistoryWithdraws.data);
                }
                 
            } catch (error) {
                console.error(error)
                localStorage.clear();
                navigate('/auth/login');
            }
            
        }
        if(historyWithdraws.length <= 0){
            getHistoryWithdraw()
        } 
        const getUser = async () => {
            try {
                const dataUser = await authService.getCurrentUser();
                setUser(dataUser);
            }catch (error) {
                console.error(error)
                localStorage.clear();
                navigate('/auth/login');
            }
            
        }
        if(user === null){
            getUser()
        }
     }, []);
    return (
        <>
        <div className="headerprocess"><i className="fal fa-history"></i> ประวัติ</div>
        <div className="containprocess mt-1">
            <table style={{width: "100%"}}>
                <thead className="text-center">
                    <tr>
                        <th className={
                                tabState === 'deposit'
                                ? "headdeposit active"
                                : "headdeposit"
                            }>
                            <button id="btndeposithis" onClick={() => { handleChangeTab('deposit')}}><i className="fal fa-plus-circle" style={{color: "#85c185"}}></i>ประวัตติการฝาก</button>
                        </th>
                        <th className={
                                tabState === 'withdraw'
                                ? "headwithdraw active"
                                : "headwithdraw"
                            }>
                            <button id="btnwithdrawwhis" onClick={() => { handleChangeTab('withdraw')}}><i className="fal fa-minus-circle" style={{color: "red"}}></i>ประวัตติการถอน</button>
                        </th>
                    </tr>
                </thead>
            </table>
            <div id="deposithis" style={{display: tabState === 'deposit' ? "block" : "none"}}>

                <div className="containloophisdps mt-3" >

                    {historyDeposits.length > 0 ?
                        historyDeposits.map((historyDeposit, i) => {
                            
                            return (
                            <div className="historyofdps" key={'deposit-'+i}>
                                <table width="100%">
                                    <tbody>
                                        <tr>
                                            <td width="50%" style={{paddingTop: '7px'}}>
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td style={{paddingRight: '5px'}}><img className="backlogohis" alt="" src={ user ? user.data.bank.data.imageUrl : ''}/></td>
                                                            <td style={{textAlign: 'left',lineHeight: '20px'}}>
                                                                <span className="spanofbankhis">{user && user.data.bank.nameTh}</span>
                                                                <br/>
                                                                <span className="spanofbankhis">{user && (user.bankAccountNo.length > 10 ? helpersService.formatNo(user.bankAccountNo,'###-#-#####-###') : helpersService.formatNo(user.bankAccountNo,'###-#-#####-#'))}</span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                            <td width="50%" style={{textAlign: 'left',lineHeight: '20px'}}>
                                                <div className="statushistory"><span style={{background: '#619d61'}}>{historyDeposit.status === 'APPROVE' ? 'สำเร็จ' : historyDeposit.status === 'PENDING' ? 'กำลังดำเนินการ' : historyDeposit.status === 'REJECT' ? 'ยกเลิก' : null}</span></div>
                                                <div className="moneyhisdps"> <i className="fal fa-plus-circle plushis"></i> {historyDeposit.amount} บาท</div>
                                                <div style={{fontSize: "11px",textAlign: "right"}}>{historyDeposit.approveDate}</div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            )
                        }) : <div className="mt-5">ไม่พบข้อมูล</div>
                    }
                    
                </div>
            </div>
            <div id="withdrawwhis" style={{display: tabState === 'withdraw' ? "block" : "none"}} >

                <div className="containloophiswd mt-3">

                    {historyWithdraws.length > 0 ?
                        historyWithdraws.map((historyWithdraw, i) => {
                            
                            return (
                             <div className="historyofwd" key={'withdraw-'+i}>
                                <table width="100%">
                                    <tbody>
                                        <tr>
                                            <td width="50%" style={{paddingTop: '7px'}}>
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td style={{paddingRight: '5px'}}><img className="backlogohis" alt="" src={ user && user.data.bankWithdraw ? user.data.bankWithdraw.data.imageUrl : user.data.bank.data.imageUrl} /></td>
                                                            <td style={{textAlign: 'left',lineHeight: '20px'}}>
                                                                <span className="spanofbankhis">{user && user.data.bankWithdraw ? user.data.bankWithdraw.nameTh : user.data.bank.nameTh}</span>
                                                                <br/>
                                                                <span className="spanofbankhis">{user && user.data.bankWithdraw ? (user.withdrawBankAccountNo && user.withdrawBankAccountNo.length > 10 ? helpersService.formatNo(user.withdrawBankAccountNo,'###-#-#####-###') : helpersService.formatNo(user.withdrawBankAccountNo,'###-#-#####-#')) : (user.bankAccountNo.length > 10 ? helpersService.formatNo(user.bankAccountNo,'###-#-#####-###') : helpersService.formatNo(user.bankAccountNo,'###-#-#####-#'))}</span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                            <td width="50%" style={{textAlign: 'left',lineHeight: '20px'}}>
                                                <div className="statushistory"><span style={{background: '#619d61'}}>{historyWithdraw.status === 'APPROVE' ? 'สำเร็จ' : historyWithdraw.status === 'PENDING' ? 'กำลังดำเนินการ' : historyWithdraw.status === 'REJECT' ? 'ยกเลิก' : null}</span>
                                                </div>
                                                <div className="moneyhisdps"> <i className="fal fa-minus-circle minushis"></i> {helpersService.encodeMoneyFormat(historyWithdraw.amount)} บาท</div>
                                                <div style={{fontSize: '11px',textAlign: "right"}}>{historyWithdraw.approveDate}</div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            
                            )
                        }) : <div className="mt-5">ไม่พบข้อมูล</div>
                    }
                   
                </div>
            </div>
        </div>

        </>
    );
}
export default History;